import React from "react";

const CTASection = () => {
  return (
    <section
      className="relative bg-[#0b0c10] text-white py-16 px-6"
      style={{
        backgroundImage: `url('/assets/ctabg.svg')`, // Replace with your image path
        backgroundSize: "cover", // Ensure the image covers the entire section
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Avoid repeating the image
      }}
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center align-left text-left" style={{ paddingLeft: "6rem" }}>

        {/* Left Content (Text) */}
        <div className="md:w-2/4 w-full mb-8 md:mb-0">
          <h2 className="text-2xl md:text-3xl font-bold uppercase">
            Transform communication with powerful and innovative{" "}
            <span className="text-[#0a26e5]">
              Voice AI solutions.
            </span>
          </h2>
          <p className="text-gray-400 mt-4">
            Improve efficiency and accuracy with advanced Speech Recognition
            and Voice AI solutions designed for your business.
          </p>
          <button className="mt-6 bg-[#0a26e5] text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#0737a5] transition-all">
            Get A Quote
          </button>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
