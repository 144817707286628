import React from 'react';

const Services = () => {
  const services = [
    {
      title: 'Voice-Controlled Navigation',
      description:
        'Enable your users to navigate through your website with ease using their voice for directing the website.',
      icon: '/assets/Voice.svg', // Path to the icon image
    },
    {
      title: 'Accurate Speech Recognition',
      description:
        'Utilize incorporated AI for efficient real-time voice input and outputs, accurate and timely.',
      icon: '/assets/Accurate.svg', // Path to the icon image
    },
    {
      title: 'Multilingual Voice AI',
      description:
        'Transform communication with multilingual voice recognition, delivering accurate, in multiple languages.',
      icon: '/assets/Multilingual.svg', // Path to the icon image
    },
    {
      title: 'Personalized Voice Experiences',
      description:
        'Improve the interaction of users by providing them with accompanied voice recognition that is driven by AI techniques.',
      icon: '/assets/Personalized.svg', // Path to the icon image
    },
  ];

  return (
    <div
      id="services"
      style={{
        backgroundColor: '#02071d',
        color: 'white',
        textAlign: 'center',
        padding: '3rem 1.5rem',
      }}
    >
      {/* Header Section */}
      <h2 style={{ fontSize: '1.8rem', marginBottom: '2rem', fontWeight: 'bold' }}>
        UNLOCK THE POWER OF VOICE WITH THESE
        <span style={{ color: '#0066ff' }}> INNOVATIVE AI SOLUTIONS</span>
      </h2>

      {/* Services Grid */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1.5rem',
          flexWrap: 'wrap',
        }}
      >
        {services.map((service, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#1b1b35',
              padding: '2rem',
              borderRadius: '10px',
              flex: '1',
              maxWidth: '250px',
              border: '2px solid #0b23cb',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
              transition: 'box-shadow 0.3s, transform 0.3s, border-color 0.3s',
              position: 'relative',
              overflow: 'hidden',
            }}
            className="service-card"
          >
            {/* Icon Image */}
            <div
              style={{
                marginBottom: '1.5rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={service.icon}
                alt={`${service.title} Icon`}
                style={{
                  height: '50px',
                  width: '50px',
                  borderRadius: '50%',
                  backgroundColor: '#0066ff',
                  padding: '5px',
                }}
              />
            </div>

            {/* Title */}
            <h3
              style={{
                fontSize: '1rem',
                marginBottom: '1rem',
                textAlign: 'center',
              }}
            >
              {service.title}
            </h3>

            {/* Description */}
            <p
              style={{
                fontSize: '0.9rem',
                lineHeight: '1.4',
                textAlign: 'justify',
              }}
            >
              {service.description}
            </p>
          </div>
        ))}
      </div>

      {/* CSS for hover effects */}
      <style jsx>{`
        .service-card:hover {
          transform: translateY(-5px);
          border-color: #0066ff;
        }
        .service-card:hover .hover-border {
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default Services;
