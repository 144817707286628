import React from "react";

const AboutUs = () => {
  return (
    <div
      id="about-us" // Added ID here
      style={{
        backgroundColor: "#02071d",
        color: "white",
        textAlign: "center",
        position: "relative",
      }}
    >
      {/* Gradient Border Wrapper */}
      <div
        style={{
          border: "2px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "15px",
          padding: "4rem",
          background: "linear-gradient(to bottom, #0b132b, #1c2431)",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        {/* About Us Header */}
        <h1
          className="text-white font-bold text-2xl md:text-3xl uppercase"
          style={{ marginBottom: "1.5rem" }}
        >
          ABOUT <span className="text-[#0a26e5]">Us</span>
        </h1>

        <p
          style={{
            fontSize: "1.2rem",
            marginBottom: "3rem",
            lineHeight: "1.8",
            color: "rgba(255, 255, 255, 0.8)",
          }}
        >
          Transform your website with our modern Gen AI solutions, leveraging Speech Recognition and Voice AI for intuitive, real-time user engagement.

        </p>

        {/* Mission, Vision, and Values Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          {/* Mission Card */}
          <div
            style={{
              backgroundColor: "#1b1b35",
              padding: "2rem",
              borderRadius: "10px",
              flex: "1",
              minWidth: "280px",
              maxWidth: "320px",
              textAlign: "center",
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: "bold",
                color: "#00aaff",
              }}
            >
              OUR MISSION
            </h3>
            <p
              style={{
                lineHeight: "1.8",
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1rem",
                textAlign: "justify",
              }}
            >
              We aim to enhance digital experiences using advanced Speech Recognition and Voice AI technologies.

            </p>
          </div>

          {/* Decorative Arrow Divider */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                background: "linear-gradient(to bottom, #0b132b, #1c2431)",
                border: "2px solid #00aaff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {/* Empty circle for decoration */}
            </div>
          </div>

          {/* Vision Card */}
          <div
            style={{
              backgroundColor: "#1b1b35",
              padding: "2rem",
              borderRadius: "10px",
              flex: "1",
              minWidth: "280px",
              maxWidth: "320px",
              textAlign: "center",
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <h3
              style={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: "bold",
                color: "#00aaff",
              }}
            >
              OUR VISION
            </h3>
            <p
              style={{
                lineHeight: "1.8",
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1rem",
                textAlign: "justify",
                
              }}
            >
              We are revolutionizing digital communication with advanced Speech Recognition and Voice AI, enabling interactions between humans and technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
