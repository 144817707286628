import React from "react";

const HeroSection = () => {
  return (
    <div
    className="relative bg-[#02071d] text-white h-screen w-screen p-0 m-0 flex items-center justify-center bg-cover bg-center"

      style={{ backgroundImage: "url('/assets/herobg.svg')" }}
    >
      {/* Background Graphics */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-[#02071d] to-transparent"></div>
      </div>

      {/* Content */}
      <div className="z-10 text-center px-6 md:px-12">
        <h1 className="text-3xl md:text-5xl font-bold leading-tight mb-4">
          Revolutionize Communication <br />
          with Advanced{" "}
          <span className="text-[#267dff] bg-[#101c45] px-2 rounded-md">
            Voice AI
          </span>
        </h1>
        <p className="text-gray-400 text-lg md:text-xl mb-6">
          Elevate user interactions using smart speech recognition <br />
          And innovative Voice AI technology solutions.
        </p>
        <a
          href="#chat"
          className="bg-[#267dff] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#1057c8] transition duration-300"
        >
          Try Our Sinc
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
