import React from 'react';

const Header = () => {
  const homepageURL = "https://tekexpertsinc.com/"; // Replace with your actual domain

  return (
    <header
      className="pb-6 lg:pb-0"
      style={{ backgroundColor: '#0c112e', position: 'sticky', top: 0, zIndex: 9999 }}
    >
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Large Screens (lg and above) */}
        <nav className="flex items-center justify-between h-16 lg:h-20">
          {/* Left: Logo */}
          <div className="flex-shrink-0">
            <a href="#" title="Logo" className="flex">
              <img
                className="w-auto h-8 lg:h-10"
                src="./assets/logo.svg"
                alt="Logo"
              />
            </a>
          </div>

          {/* Hamburger Menu for small screens */}
          <button
            type="button"
            className="inline-flex p-2 text-black transition-all duration-400 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
          >
            <svg
              className="block w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 8h16M4 16h16"
              />
            </svg>
          </button>

          {/* Center: Navigation Menu for large screens */}
          <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            <a
              href={`${homepageURL}#about-us`}
              title="About Us"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              About Us
            </a>

            <a
              href={`${homepageURL}#services`}
              title="Solutions"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              Solutions
            </a>

            <a
              href={`${homepageURL}#testimonials`}
              title="Testimonials"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              Testimonials
            </a>

            <a
              href={`${homepageURL}#pricing`}
              title="Pricing"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              Pricing
            </a>

            <a
              href={`${homepageURL}#FAQ`}
              title="FAQ"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              FAQ
            </a>

            <a
              href={`${homepageURL}#contact-us-section`}
              title="Contact Us"
              className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              Contact Us
            </a>
          </div>

          {/* Right: Animated TekSinc AI Button */}
          <a
            href="#chat"
            title="TekSinc AI"
            className="items-center justify-center hidden px-4 py-3 ml-10 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700 animate-pulse"
            role="button"
          >
            TekSinc AI
          </a>
        </nav>

        {/* Small screens menu */}
        <nav className="pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden">
          <div className="flow-root">
            <div className="flex flex-col px-6 -my-2 space-y-1">
              <a
                href={`${homepageURL}#about-us`}
                title="About Us"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
              >
                About Us
              </a>

              <a
                href={`${homepageURL}#services`}
                title="Solutions"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
              >
                Solutions
              </a>

              <a
                href={`${homepageURL}#pricing`}
                title="Pricing"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
              >
                Pricing
              </a>

              <a
                href={`${homepageURL}#contact-us-section`}
                title="Contact Us"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
              >
                Contact Us
              </a>
            </div>
          </div>

          <div className="px-6 mt-6">
            <a
              href={`${homepageURL}`}
              title="Get started"
              className="inline-flex justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:bg-blue-700"
              role="button"
            >
              Get started now
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
