import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#0b0c10] text-gray-400 py-10 px-6">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Column 1: Logo, Description, Social Icons */}
        <div className="flex justify-center items-start">
  <div className="text-left">
  <div className="mb-4">
  <a href="#" title="Logo" className="flex">
    <img
      className="w-auto h-8 lg:h-10" // Adjust the size as per your design
      src="./assets/logo.svg" // Path to your logo image
      alt="Logo"
    />
  </a>
</div>
          <p className="mb-4 text-justify">
          Transform your business with our innovative Speech Recognition and Voice AI solutions. Boost efficiency, automate communication, and deliver exceptional experiences with technology that adapts to your unique needs.
          </p>
          <div className="flex text-left space-x-4"> {/* Centered social media icons */}
            <a href="https://web.facebook.com/tekexpertsinc/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#0a26e5]">
              <FaFacebookF />
            </a>
            <a href="https://www.linkedin.com/company/tekexpertsinc/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#0a26e5]">
              <FaLinkedinIn />
            </a>
            <a href="https://x.com/Tek_EXpe" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#0a26e5]">
              <FaTwitter />
            </a>
            <a href="https://www.youtube.com/@Tek_EXpe" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#0a26e5]">
              <FaYoutube />
            </a>
          </div>
        </div>
        </div>

        {/* Column 2: Solutions */}
        <div className="flex justify-center items-start">
  <div className="text-left">
    <h3 className="text-white text-lg font-semibold mb-4">Solutions</h3>
    <ul className="space-y-2">
      <li>
        <a href="#services" className="hover:text-[#0166ff]">Voice-Controlled Navigation</a>
      </li>
      <li>
        <a href="#services" className="hover:text-[#0166ff]">Accurate Speech Recognition</a>
      </li>
      <li>
        <a href="#servicess" className="hover:text-[#0166ff]">Multilingual Voice AI</a>
      </li>
      <li>
        <a href="#services" className="hover:text-[#0166ff]">Personalized Voice Experiences</a>
      </li>
    </ul>
  </div>
</div>

        {/* Column 3: Help Links */}
        <div className="flex justify-center items-start">
  <div className="text-left">
          <h3 className="text-white text-lg font-semibold mb-4">Help Links</h3>
          <ul className="space-y-2">
            <li>
              <a href="/privacypolicy" className="hover:text-[#0166ff]">Privacy Policy</a>
            </li>
            <li>
              <a href="/privacypolicy" className="hover:text-[#0166ff]">Terms & Conditions</a>
            </li>
          </ul>
        </div>
        </div>

        {/* Column 4: Contact Details */}
        <div className="flex justify-center items-start">
  <div className="text-left">
          <h3 className="text-white text-lg font-semibold mb-4">Contact Details</h3>
          <ul className="space-y-2">
          <li>
  <a 
    href="https://www.google.com/maps?q=No+109+Sumanathissa+Mawatha,+Armour+Street,+01200+Colombo+Western+Sri+Lanka" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="hover:text-[#0166ff]"
  >
    No 109 Sumanathissa Mawatha, Armour Street, 01200 Colombo Western Sri Lanka
  </a>
</li>

            
            <li>
              <a href="tel:0112 238 483" className="hover:text-[#0166ff]">0112 238 483</a>
            </li>
            <li>
              <a href="tel:076 589 4927" className="hover:text-[#0166ff]">076 589 4927</a>
            </li>
            <li>
              <a href="mailto:info@braverinnovation.com" className="hover:text-[#0166ff]">info@tekexpertsinc.com</a>
            </li>
          </ul>
        </div>
      </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center text-gray-500 text-sm mt-10">
      <p>Copyright © 2021-2024 <a href="https://tekexpertsinc.com/" class="text-[#0166ff]">Tekexpertsinc.com.</a> All Rights Reserved.</p>

      </div>
    </footer>
  );
};

export default Footer;
