const WhyChooseUs = () => {
  return (
    <section className="min-h-screen text-white py-16 bg-[#02071d] flex flex-col items-center">
      
      {/* Top Row: Heading */}
      <div className="w-full text-center">
        <h2 className="text-white font-bold text-2xl md:text-3xl uppercase" style={{ marginBottom: "1.5rem" }}>
          WHY <span className="text-[#0a26e5]">Choose Us</span>
        </h2>
      </div>

      {/* Second Row: Two Columns */}
      <div className="container mx-auto flex flex-col md:flex-row mt-16 space-y-6 md:space-y-0 md:space-x-6">

        {/* First Column: Card Layout with Background Circle */}
        <div className="md:w-1/2 flex justify-center relative">
          <div
            className="absolute inset-0 z-0"
            style={{
              backgroundImage: `url('/assets/circlebg.svg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '50%',
              width: '100%',
              height: '100%',
            }}
          ></div>
          <div className="grid grid-cols-2 gap-4 p-6 rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-2 relative z-10">
            {/* Card 1 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden items-end justify-end transform transition-transform duration-300 hover:-translate-x-2 hover:-translate-y-2"
              style={{
                borderColor: "#0b23cb",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                borderTopLeftRadius: "300px",
                borderBottomLeftRadius: "15px",
              }}
            >
              <p className="text-sm font-semibold leading-6 text-gray-300 text-left">
                Completed Projects
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-left">
                20+
              </p>
            </div>

            {/* Card 2 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden items-start justify-end transform transition-transform duration-300 hover:translate-x-2 hover:-translate-y-2"
              style={{
                borderColor: "#0b23cb",
                borderTopRightRadius: "300px",
                borderBottomRightRadius: "15px",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            >
              <p className="text-sm font-semibold leading-6 text-gray-300 text-right">
                Years of Experience
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                4+
              </p>
            </div>

            {/* Card 3 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden items-end justify-start transform transition-transform duration-300 hover:-translate-x-2 hover:translate-y-2"
              style={{
                borderColor: "#0b23cb",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "300px",
              }}
            >
              <p className="text-sm font-semibold leading-6 text-gray-300 text-right">
                Satisfied Clients
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                15+
              </p>
            </div>

            {/* Card 4 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden items-start justify-start transform transition-transform duration-300 hover:translate-x-2 hover:translate-y-2"
              style={{
                borderColor: "#0b23cb",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "300px",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            >
              <p className="text-sm font-semibold leading-6 text-gray-300 text-left">
                New Clients
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-left">
                150+
              </p>
            </div>
          </div>
        </div>

        {/* Second Column: 4 Square Cards */}
        <div className="md:w-1/2 flex justify-center">
          <div className="grid grid-cols-2 gap-4 p-6">
            
            {/* Square Card 1 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden justify-center items-center transform transition-transform duration-300 hover:-translate-x-2 hover:-translate-y-2"
              style={{ borderColor: "#0b23cb" }}
            >
              <p className="text-sm font-semibold tracking-tight text-white text-justify">
                Users can interact with websites naturally through voice, creating a more human-like and intuitive experience.
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                Communication
              </p>
            </div>

            {/* Square Card 2 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden justify-center items-center transform transition-transform duration-300 hover:translate-x-2 hover:-translate-y-2"
              style={{ borderColor: "#0b23cb" }}
            >
              <p className="text-sm font-semibold tracking-tight text-white text-justify">
                Increase efficiency, reduce manual tasks, and optimize workflows with AI-powered solutions for enhanced productivity and growth.
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                Productivity
              </p>
            </div>

            {/* Square Card 3 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden justify-center items-center transform transition-transform duration-300 hover:-translate-x-2 hover:-translate-y-2"
              style={{ borderColor: "#0b23cb" }}
            >
              <p className="text-sm font-semibold tracking-tight text-white text-justify">
                Advanced voice recognition technology ensures precise interpretation of commands, reducing errors and misunderstandings.
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                Accuracy
              </p>
            </div>

            {/* Square Card 4 */}
            <div
              className="flex flex-col bg-white/5 p-8 h-64 w-64 border overflow-hidden justify-center items-center transform transition-transform duration-300 hover:-translate-x-2 hover:-translate-y-2"
              style={{ borderColor: "#0b23cb" }}
            >
              <p className="text-sm font-semibold tracking-tight text-white text-justify">
                Multilingual support in Speech Recognition makes websites more inclusive, catering to diverse user groups worldwide.
              </p>
              <p className="order-first text-3xl font-semibold tracking-tight text-white text-right">
                Accessibility
              </p>
            </div>

          </div>
        </div>

      </div>
    </section>
  );
};

export default WhyChooseUs;
