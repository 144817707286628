import React from "react";

function PricingSection() {
  return (
    <section className="py-16 bg-[#02071d] text-white px-4 sm:px-8 lg:px-16" id="pricing">
      <div className="container mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8">
          OUR <span className="text-blue-500">PRICING PLANS</span>
        </h2>
        <div className="flex flex-wrap justify-center gap-8">
          {/* Basic Plan */}
          <div className="w-full max-w-sm p-6 bg-transparent border-2 border-[#0166ff] rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Basic Plan</h3>
            <p className="text-lg mb-6">$19/month</p>
            <ul className="space-y-4">
              <li className="flex items-center">
                <span>✔ 50GB Storage</span>
              </li>
              <li className="flex items-center">
                <span>✔ Email Support</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ AI Customization</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ Advanced Speech</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ 24/7 Support</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ Account Manager</span>
              </li>
            </ul>
            <div className="mt-6 text-center">
              <a
                href="#contact-us-section"
                className="inline-flex items-center justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-[#0166ff] rounded-md hover:bg-blue-700 focus:bg-blue-700"
              >
                Get Started
              </a>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="w-full max-w-sm p-6 bg-transparent border-2 border-[#0166ff] rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Pro Plan</h3>
            <p className="text-lg mb-6">$49/month</p>
            <ul className="space-y-4">
              <li className="flex items-center">
                <span>✔ 100GB Storage</span>
              </li>
              <li className="flex items-center">
                <span>✔ Email Support</span>
              </li>
              <li className="flex items-center">
                <span>✔ Speech AI</span>
              </li>
              <li className="flex items-center">
                <span>✔ Priority Support</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ 24/7 Support</span>
              </li>
              <li className="flex items-center line-through opacity-50">
                <span>✔ Account Manager</span>
              </li>
            </ul>
            <div className="mt-6 text-center">
              <a
                href="#contact-us-section"
                className="inline-flex items-center justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-[#0166ff] rounded-md hover:bg-blue-700 focus:bg-blue-700"
              >
                Get Started
              </a>
            </div>
          </div>

          {/* Enterprise Plan */}
          <div className="w-full max-w-sm p-6 bg-transparent border-2 border-[#0166ff] rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Enterprise Plan</h3>
            <p className="text-lg mb-6">$99/month</p>
            <ul className="space-y-4">
              <li className="flex items-center">
                <span>✔ 200GB Storage</span>
              </li>
              <li className="flex items-center">
                <span>✔ Full Customization</span>
              </li>
              <li className="flex items-center">
                <span>✔ 24/7 Support</span>
              </li>
              <li className="flex items-center">
                <span>✔ Speech Insights</span>
              </li>
              <li className="flex items-center">
                <span>✔ Account Manager</span>
              </li>
              <li className="flex items-center">
                <span>✔ Beta Access</span>
              </li>
            </ul>
            <div className="mt-6 text-center">
              <a
                href="#contact-us-section"
                className="inline-flex items-center justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-[#0166ff] rounded-md hover:bg-blue-700 focus:bg-blue-700"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;
