import React, { useState, useEffect } from "react";

const VoiceAiModel = () => {
  const [transcription, setTranscription] = useState("");
  const [inputText, setInputText] = useState("");
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const recognitionInstance = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognitionInstance.lang = "en-US";
      recognitionInstance.interimResults = true;

      recognitionInstance.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setTranscription(transcript);
      };

      setRecognition(recognitionInstance);
    }
  }, []);

  const startRecognition = () => {
    if (recognition) {
      recognition.start();
    }
  };

  const speakText = (text) => {
    const speechSynthesis = window.speechSynthesis;
    if (speechSynthesis) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    }
  };

  const handleSpeakText = () => {
    if (inputText.trim()) {
      speakText(inputText);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.columns}>
        {/* Left Column - Description Section */}
        <div id="chat"  style={styles.leftColumn}>
        <h1
          className="text-white font-bold text-2xl md:text-3xl uppercase"
          style={{ marginBottom: "1.5rem" }}
        >
          TekSinc <span className="text-[#0a26e5]">AI</span>
        </h1>

          <p style={styles.paragraph}>
            Welcome to <strong>TekSinc AI</strong>, powered by <strong>NVIDIA RIVA</strong>. Experience real-time 
            voice-to-text transcription and natural text-to-speech synthesis using cutting-edge AI technology.
          </p>

          <div style={styles.chatInterface}>
            <div style={styles.chatItem}>
              <button onClick={startRecognition} style={styles.iconBtn}>
                <i className="fas fa-microphone" style={styles.icon}></i>
              </button>
              <textarea
                value={transcription}
                placeholder="Say something..."
                readOnly
                rows="3"
                style={styles.textarea}
              />
            </div>
            <div style={styles.chatItem}>
              <textarea
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Type something..."
                rows="3"
                style={styles.textarea}
              />
              <button onClick={handleSpeakText} style={styles.iconBtn}>
                <i className="fas fa-volume-up" style={styles.icon}></i>
              </button>
            </div>
          </div>
          
        </div>

        {/* Right Column - Chat Interface */}
        <div style={styles.rightColumn}>
        <div style={styles.steps}>
            <h2 style={styles.stepsHeading}>How to Use</h2>
            <div style={styles.stepBox}>
              <span style={styles.stepNumber}>1</span>
              <p style={styles.stepText}>Click the microphone button to start recording your voice and see it transcribed in real-time.</p>
            </div>
            <div style={styles.stepBox}>
              <span style={styles.stepNumber}>2</span>
              <p style={styles.stepText}>Type your text in the input box below and click the speaker icon to hear it spoken aloud.</p>
            </div>
            <div style={styles.stepBox}>
              <span style={styles.stepNumber}>3</span>
              <p style={styles.stepText}>Enjoy seamless interaction with our intuitive interface, designed for efficiency.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Inline styles with animations and alignment
const styles = {
  container: {
    padding: "40px",
    backgroundColor: "#02071d",
    color: "white",
    fontFamily: "'Roboto', Segoe UI",
  },
  columns: {
    display: "flex",
    flexWrap: "wrap",
    gap: "40px",
  },
  leftColumn: {
    flex: "1",
    minWidth: "300px",
    animation: "fadeInLeft 1s ease-in-out",
  },
  rightColumn: {
    flex: "1",
    minWidth: "300px",
    animation: "fadeInRight 1s ease-in-out",
  },
  heading: {
    fontSize: "2.5em",
    color: "#2663e8",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "1.2em",
    lineHeight: "1.6",
    color: "#d1d5db",
    marginBottom: "20px",
  },
  steps: {
    marginTop: "20px",
  },
  stepsHeading: {
    fontSize: "1.5em",
    color: "#2663e8",
    marginBottom: "10px",
  },
  stepBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#121827",
    borderRadius: "10px",
    padding: "15px",
    marginBottom: "10px",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.3s ease",
  },
  stepBoxHover: {
    transform: "scale(1.5)",
  },
  stepNumber: {
    backgroundColor: "#2663e8",
    color: "white",
    fontSize: "1.5em",
    fontWeight: "bold",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  stepText: {
    fontSize: "1.1em",
    color: "#d1d5db",
  },
  chatInterface: {
    backgroundColor: "#121827",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  },
  chatItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  iconBtn: {
    backgroundColor: "#2663e8",
    border: "none",
    padding: "10px",
    borderRadius: "50%",
    cursor: "pointer",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    transition: "background-color 0.3s ease",
  },
  icon: {
    fontSize: "20px",
    color: "white",
  },
  textarea: {
    flex: 1,
    padding: "10px",
    fontSize: "1em",
    backgroundColor: "#1c2a39",
    color: "white",
    border: "none",
    borderRadius: "10px",
    resize: "none",
    outline: "none",
  },
};

export default VoiceAiModel;
