import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#02071d", // Background color as per your requirement
    color: "#fff", // Text color for readability
    minHeight: "100vh", // Ensure full height
  };

  const contentStyle = {
    textAlign: "justify", // Justify the text
    lineHeight: "1.8", // Better line spacing for readability
    margin: "0 auto", // Center the container horizontally
    maxWidth: "1200px", // Limit the content width for better layout
    padding: "15px", // Add padding inside the content
    borderRadius: "10px", // Rounded corners for aesthetic
    backgroundColor: "#121827", // Slightly lighter inner container
  };

  const headingStyle = {
    color: "#2663e8", // Blue color for h1 and h2
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        
        <p><strong>Last updated:</strong> December 02, 2024</p>
        <br />
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use, and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <br />
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <br />

        <h2 style={headingStyle}><strong>Interpretation and Definitions </strong></h2>
        <br />

        <h3 style={headingStyle}>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions shall
          have the same meaning regardless of whether they appear in singular or
          plural.
        </p>
        <br />

        <h3 style={headingStyle}>Definitions</h3>
        <ul>
          <li>
            <strong>Account:</strong> A unique account created for You to access
            our Service or parts of our Service.
          </li>
          <li >
            <strong style={headingStyle}>Affiliate:</strong> An entity that controls, is controlled by
            or is under common control with a party, where "control" means
            ownership of 50% or more of the shares, equity interest, or other
            securities entitled to vote for election of directors or other
            managing authority.
          </li>
          <li>
            <strong style={headingStyle}>Company:</strong> Referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement, refers to TekExperts PVT LTD, No 109
            Sumanathissa Mawatha, Armour Street, 01200 Colombo Western Sri Lanka.
          </li>
          <li>
            <strong style={headingStyle}>Cookies:</strong> Small files placed on Your computer, mobile
            device, or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li>
            <strong style={headingStyle}>Country:</strong> Refers to Sri Lanka.
          </li>
          <li>
            <strong style={headingStyle}>Device:</strong> Any device that can access the Service such
            as a computer, a cellphone, or a digital tablet.
          </li>
          <li>
            <strong style={headingStyle}>Personal Data:</strong> Any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <strong style={headingStyle}>Service:</strong> Refers to the Website.
          </li>
          <li>
            <strong style={headingStyle}>Service Provider:</strong> Any natural or legal person who
            processes the data on behalf of the Company. It refers to third-party
            companies or individuals employed by the Company to facilitate the
            Service, provide the Service on behalf of the Company, perform
            services related to the Service, or assist the Company in analyzing
            how the Service is used.
          </li>
          <li>
            <strong style={headingStyle}>Usage Data:</strong> Data collected automatically, either
            generated by the use of the Service or from the Service infrastructure
            itself (for example, the duration of a page visit).
          </li>
          <li>
            <strong style={headingStyle}>Website:</strong> Refers to tekexpertsinc, accessible from{" "}
            <a href="https://tekexpertsinc.com/" style={{ color: "#1e90ff" }}>
              https://tekexpertsinc.com/
            </a>.
          </li>
          <li>
            <strong style={headingStyle}>You:</strong> The individual accessing or using the Service,
            or the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </li>
        </ul>
        <br />

        <h2 style={headingStyle}>Collecting and Using Your Personal Data</h2>
        <h3 style={headingStyle}>Types of Data Collected</h3>
        <h4 style={headingStyle}>Personal Data</h4>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </ul>
        <br />

        <h4 style={headingStyle}>Usage Data</h4>
        <p>
          Usage Data is collected automatically when using the Service. It may
          include information such as Your Device's Internet Protocol address
          (e.g., IP address), browser type, browser version, the pages You visit
          on our Service, the time and date of Your visit, the time spent on those
          pages, unique device identifiers, and other diagnostic data.
        </p>
        <br />

        {/* Add more sections as needed */}
        <h3 style={headingStyle}>Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, you can contact us
          by email at{" "}
          <a href="mailto:info@tekexpertsinc.com" style={{ color: "#1e90ff" }}>
            info@tekexpertsinc.com
          </a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
