import React from 'react';

const ContactUs = () => {
  return (
    <div 
      id="contact-us-section"  // Added id to the main div
      style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#02071d', color: 'white', padding: '2rem' }}
    >
      {/* Left Column - Image/Illustration */}
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src="assets/contactus.svg" // Replace with the path to your image
          alt="Contact Illustration"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>

      {/* Right Column - Form */}
      <div 
        id="contact-form"  // Added id to the form container div
        style={{ flex: 1, padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <h1
          className="text-white font-bold text-2xl md:text-3xl uppercase"
          style={{ marginBottom: "1.5rem" }}
        >
          CONTACT <span className="text-[#0a26e5]">Us</span>
        </h1>
        <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <input
              id="contact-name" // Added id for the name input
              type="text"
              placeholder="Your name"
              style={{
                borderColor: '#0b23cb',
                flex: 1,
                padding: '0.8rem',
                borderRadius: '5px',
                border: '1px solid #555',
                backgroundColor: '#1b1b35',
                color: 'white',
              }}
            />
            <input
              id="contact-email" // Added id for the email input
              type="email"
              placeholder="Your mail"
              style={{
                borderColor: '#0b23cb',
                flex: 1,
                padding: '0.8rem',
                borderRadius: '5px',
                border: '1px solid #555',
                backgroundColor: '#1b1b35',
                color: 'white',
              }}
            />
          </div>
          <input
            id="contact-subject" // Added id for the subject input
            type="text"
            placeholder="Subject"
            style={{
              borderColor: '#0b23cb',
              padding: '0.8rem',
              borderRadius: '5px',
              border: '1px solid #555',
              backgroundColor: '#1b1b35',
              color: 'white',
            }}
          />
          <textarea
            id="contact-message" // Added id for the message textarea
            placeholder="Message"
            rows="6"
            style={{
              borderColor: '#0b23cb',
              padding: '0.8rem',
              borderRadius: '5px',
              border: '1px solid #555',
              backgroundColor: '#1b1b35',
              color: 'white',
            }}
          />
          <button
            type="submit"
            style={{
              borderColor: '#0b23cb',
              padding: '0.8rem',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
