import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './global.css';


import './App.css';
import Header from './Components/Header';
import HeroSection from './Components/HeroSection'; 
import VoiceAIModel from './Components/VoiceAIModel';
import AboutUs from './Components/AboutUs';
import WhyChooseUs from './Components/WhyChooseUs';
import Services from './Components/Services';
import Testimonials from './Components/Testimonials';
import SolutionsSection from './Components/SolutionsSection';
import FAQSection from './Components/FAQSection';
import ContactUs from './Components/ContactUs';
import CTASection from './Components/CTASection';
import Footer from './Components/Footer';
import PricingSection from './Components/PricingSection';
import PrivacyPolicy from './Components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          {/* Define the home page route */}
          <Route
            path="/"
            element={
              <>
                
                <HeroSection />
                <VoiceAIModel/>
                <AboutUs />
                <WhyChooseUs />
                <Services />
                <Testimonials />
                <SolutionsSection />
                <FAQSection />
                <PricingSection />
                <ContactUs />
                <CTASection />
                
              </>
            }
          />

          {/* Privacy Policy Route */}
          <Route
            path="/privacypolicy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
