import React from 'react';

const SolutionsSection = () => {
  return (
    <section className="bg-[#0b0c10] py-16">
      {/* Heading Section */}
      <div className="text-center mb-12">
        <h2 className="text-white font-bold text-2xl md:text-3xl uppercase">
        Our Streamlined Process for Speech Recognition and  <span className="text-[#0a26e5]">Voice AI Solutions</span>
        </h2>
      </div>

      {/* Cards Section */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-6">
        {/* Card 1 */}
        <div className="relative flex flex-col items-center">
          {/* Large Circle */}
          <div className="w-36 h-36 border-4 border-[#0a26e5] rounded-full flex items-center justify-center z-0">
            <img src="/assets/Assessment.svg" alt="Solution Design" className="w-20 h-20" />
          </div>
          {/* Box Overlapping the Circle */}
          <div className="bg-[#02071d] p-6 text-center rounded-lg -mt-10 z-10">
            <h3 className="text-white font-bold text-lg mb-2">Assessment</h3>
            <p className="text-gray-400 text-sm">
            We analyze your business needs to determine the best Speech Recognition and Voice AI solution tailored to your requirements.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="relative flex flex-col items-center">
          {/* Large Circle */}
          <div className="w-36 h-36 border-4 border-[#0a26e5] rounded-full flex items-center justify-center z-0">
            <img src="/assets/Customization.svg" alt="Deployment and Integration" className="w-20 h-20" />
          </div>
          {/* Box Overlapping the Circle */}
          <div className="bg-[#02071d] p-6 text-center rounded-lg -mt-10 z-10">
            <h3 className="text-white font-bold text-lg mb-2">Customization</h3>
            <p className="text-gray-400 text-sm">
            Our team customizes the AI solution, ensuring seamless integration with your existing systems for enhanced functionality and performance.
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className="relative flex flex-col items-center">
          {/* Large Circle */}
          <div className="w-36 h-36 border-4 border-[#0a26e5] rounded-full flex items-center justify-center z-0">
            <img src="/assets/Implementation.svg" alt="Performance Optimization" className="w-20 h-20" />
          </div>
          {/* Box Overlapping the Circle */}
          <div className="bg-[#02071d] p-6 text-center rounded-lg -mt-10 z-10">
            <h3 className="text-white font-bold text-lg mb-2">Implementation</h3>
            <p className="text-gray-400 text-sm">
            We implement the AI solution swiftly and efficiently, providing full support to ensure smooth transition and minimal downtime.

            </p>
          </div>
        </div>

        {/* Card 4 */}
        <div className="relative flex flex-col items-center">
          {/* Large Circle */}
          <div className="w-36 h-36 border-4 border-[#0a26e5] rounded-full flex items-center justify-center z-0">
            <img src="/assets/Optimization.svg" alt="Our Success" className="w-20 h-20" />
          </div>
          {/* Box Overlapping the Circle */}
          <div className="bg-[#02071d] p-6 text-center rounded-lg -mt-10 z-10">
            <h3 className="text-white font-bold text-lg mb-2">Optimization</h3>
            <p className="text-gray-400 text-sm">
            Continuous monitoring and optimization are provided to ensure performance, accuracy, and adaptability to evolving business needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsSection;
