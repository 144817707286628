import React from "react";

const Testimonials = () => {
  return (
    <section id="testimonials" className="relative bg-[#0b0c10] text-white py-16">
      
  
  {/* Content */}
  <div className="relative z-10 container mx-auto">
    {/* Heading */}
    <div className="text-center mb-12">
      <h2 className="text-white font-bold text-2xl md:text-3xl uppercase">
        What Our Customers Are{" "}
        <span className="text-[#0a26e5]">Talking About Us</span>
      </h2>
    </div>

    {/* Testimonials Grid */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6">
      {/* Testimonial 1 */}
      <div className="bg-[#02071d] rounded-lg shadow-md border border-[#0a26e5] p-6 relative">
        {/* Quotation Marks */}
        <div className="absolute top-4 left-4 text-[#0a26e5] text-4xl font-bold">“</div>
        <div className="absolute bottom-4 right-4 text-[#0a26e5] text-4xl font-bold">”</div>

        {/* Testimonial Content */}
        <p className="text-gray-300 text-sm mb-6 leading-relaxed">
        Exceptional Speech Recognition AI! It has simplified our workflows and significantly enhanced our team's communication efficiency.

        </p>

        {/* User Info */}
        <div className="flex flex-col items-center">
          {/* Avatar */}
          <div className="w-16 h-16 rounded-full overflow-hidden border-4 border-[#0a26e5] mb-4">
            <img
              src="/assets/Michael.svg"
              alt="Rustyn"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Name and Role */}
          <h4 className="text-white font-bold text-lg">Michael Davis</h4>
          <p className="text-gray-400 text-xs">Project Manager</p>
          {/* Rating */}
          <div className="text-yellow-500 mt-2">⭐⭐⭐⭐⭐</div>
        </div>
      </div>

      {/* Testimonial 2 */}
      <div className="bg-[#02071d] rounded-lg shadow-md border border-[#0a26e5] p-6 relative">
        {/* Quotation Marks */}
        <div className="absolute top-4 left-4 text-[#0a26e5] text-4xl font-bold">“</div>
        <div className="absolute bottom-4 right-4 text-[#0a26e5] text-4xl font-bold">”</div>

        {/* Testimonial Content */}
        <p className="text-gray-300 text-sm mb-6 leading-relaxed">
        Voice AI integration was flawless. The accuracy and speed of this solution have transformed our customer service experience.
        </p>

        {/* User Info */}
        <div className="flex flex-col items-center">
          {/* Avatar */}
          <div className="w-16 h-16 rounded-full overflow-hidden border-4 border-[#0a26e5] mb-4">
            <img
              src="/assets/Laura.svg"
              alt="Sophia"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Name and Role */}
          <h4 className="text-white font-bold text-lg">Laur Thompson</h4>
          <p className="text-gray-400 text-xs">Team Lead</p>
          {/* Rating */}
          <div className="text-yellow-500 mt-2">⭐⭐⭐⭐⭐</div>
        </div>
      </div>

      {/* Testimonial 3 */}
      <div className="bg-[#02071d] rounded-lg shadow-md border border-[#0a26e5] p-6 relative">
        {/* Quotation Marks */}
        <div className="absolute top-4 left-4 text-[#0a26e5] text-4xl font-bold">“</div>
        <div className="absolute bottom-4 right-4 text-[#0a26e5] text-4xl font-bold">”</div>

        {/* Testimonial Content */}
        <p className="text-gray-300 text-sm mb-6 leading-relaxed">
        Our productivity skyrocketed thanks to this Speech Recognition AI. It’s reliable and has been a game-changer for our operations.
        </p>

        {/* User Info */}
        <div className="flex flex-col items-center">
          {/* Avatar */}
          <div className="w-16 h-16 rounded-full overflow-hidden border-4 border-[#0a26e5] mb-4">
            <img
              src="/assets/James.svg"
              alt="Michael"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Name and Role */}
          <h4 className="text-white font-bold text-lg">James Anderson</h4>
          <p className="text-gray-400 text-xs">Operations Head</p>
          {/* Rating */}
          <div className="text-yellow-500 mt-2">⭐⭐⭐⭐⭐</div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default Testimonials;
