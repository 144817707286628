import React, { useState } from "react";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section
      id="FAQ"
      className="relative text-white py-12"
      style={{
        backgroundImage: "url('/assets/faqbg.svg')", // Correct path to the image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "400px", // Make sure the background covers enough area
      }}
    >
      {/* Overlay for readability */}
      <div className="absolute inset-0 bg-[#02071d]/80"></div>
      <div className="container mx-auto px-4 relative">
        <h2 className="text-3xl font-bold text-center mb-8">
          FREQUENTLY ASK <span className="text-blue-500">QUESTIONS</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Column 1 with 3 FAQs */}
          <div className="space-y-4">
            {/* FAQ Item 1 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(0)}
              >
                <h4 className="text-lg font-semibold">
                  What benefits does Speech Recognition AI offer for businesses?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 0 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 0 ? "" : "hidden"
                }`}
              >
                Speech Recognition AI boosts efficiency by automating
                transcription, improving accuracy in communication, and saving
                time on manual tasks.
              </p>
            </div>

            {/* FAQ Item 2 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(1)}
              >
                <h4 className="text-lg font-semibold">
                  How accurate is the Voice AI system?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 1 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 1 ? "" : "hidden"
                }`}
              >
                Our Voice AI system boasts a high accuracy rate, with advanced
                algorithms ensuring precise recognition, even in noisy
                environments or with varied accents.
              </p>
            </div>

            {/* FAQ Item 3 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(2)}
              >
                <h4 className="text-lg font-semibold">
                  How can I integrate Speech AI into my application?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 2 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 2 ? "" : "hidden"
                }`}
              >
                You can integrate Speech AI using our API or SDK. Detailed
                documentation and support are available to help with the
                integration process.
              </p>
            </div>
          </div>

          {/* Column 2 with 3 more FAQs */}
          <div className="space-y-4">
            {/* FAQ Item 4 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(3)}
              >
                <h4 className="text-lg font-semibold">
                  Can Speech Recognition AI be used in real-time applications?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 3 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 3 ? "" : "hidden"
                }`}
              >
                Yes, our Speech Recognition AI is optimized for real-time
                applications, providing fast and accurate results with low
                latency.
              </p>
            </div>

            {/* FAQ Item 5 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(4)}
              >
                <h4 className="text-lg font-semibold">
                  Does Speech AI support multiple languages?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 4 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 4 ? "" : "hidden"
                }`}
              >
                Yes, our Speech AI supports multiple languages, with ongoing
                updates to improve language coverage and accuracy.
              </p>
            </div>

            {/* FAQ Item 6 */}
            <div className="faq-item border border-blue-500 rounded-lg p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(5)}
              >
                <h4 className="text-lg font-semibold">
                  Is the Speech AI platform customizable for specific use cases?
                </h4>
                <svg
                  className={`faq-icon w-6 h-6 text-blue-500 transform transition-transform duration-300 ${
                    activeIndex === 5 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <p
                className={`faq-answer text-gray-300 mt-2 ${
                  activeIndex === 5 ? "" : "hidden"
                }`}
              >
                Our platform is highly customizable, with options to tailor it
                to your specific industry or use case.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
